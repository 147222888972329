import { Share } from "@capacitor/share";
import { environmentConfig } from "@src/appV2/environment";
import { SignupBasedReferralsBonusConfig } from "@src/appV2/FeatureFlags";
import { DEFAULT_REFERRAL_BONUS_AMOUNT_IN_DOLLARS } from "@src/appV2/Referral/Bonus/constants";
import { Agent } from "@src/lib/interface/src";

import { SignUpReferralBonuses } from "./types";

function makeUnique<T = number>(arr: T[]) {
  return Array.from(new Set(arr));
}

function getMsaLicenses(signUpBasedReferrals: SignupBasedReferralsBonusConfig, msa: string) {
  let forMSA = {};
  let forDefault = {};

  if (signUpBasedReferrals) {
    forMSA = signUpBasedReferrals[msa] ?? forMSA;
    forDefault = signUpBasedReferrals.default ?? forDefault;
  }

  return { forMSA, forDefault };
}

export function getSortedBonusValues(
  signUpBasedReferrals: SignupBasedReferralsBonusConfig,
  msa: string
) {
  const { forMSA, forDefault } = getMsaLicenses(signUpBasedReferrals, msa);

  const msaValues = Object.values(forMSA) as number[];
  const defaultValues = Object.values(forDefault) as number[];

  const values = makeUnique([
    ...msaValues,
    ...defaultValues,
    signUpBasedReferrals.defaultReferralRate as number,
  ])
    .filter(Boolean)
    .sort((a, b) => a - b);

  const minValue = Number.isFinite(values[0])
    ? values[0]
    : DEFAULT_REFERRAL_BONUS_AMOUNT_IN_DOLLARS;
  const maxValue = Number.isFinite(values[values.length - 1])
    ? values[values.length - 1]
    : DEFAULT_REFERRAL_BONUS_AMOUNT_IN_DOLLARS;

  return [minValue, maxValue];
}

export function getLicensesForSignUpBasedReferralsMSA(
  signUpBasedReferrals: SignupBasedReferralsBonusConfig,
  msa: string
): string {
  const { forMSA, forDefault } = getMsaLicenses(signUpBasedReferrals, msa);
  const msaKeys = Object.keys(forMSA);
  const defaultKeys = Object.keys(forDefault);

  const keys = makeUnique([...msaKeys, ...defaultKeys]).filter((key) => key !== "default");

  const order = ["CNA", "RN", "LVN", "Caregiver"];

  const availableLicenses = keys.sort((...options) => {
    const [a, b] = options.map((a) => (order.includes(a) ? order.indexOf(a) : order.length));
    return a - b;
  });

  if (availableLicenses.length === 0) {
    return "workers";
  }

  const value = availableLicenses
    .slice(0, 4)
    .map((a) => `${a}s`)
    .join(", ");
  const others = availableLicenses.length > 4 ? "or other worker types" : "";
  return `other ${value} ${others}`.trim();
}

export function getWelcomeDynamicUrl() {
  return `https://${environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN}/welcome`;
}

interface ShareReferralContentProps {
  agent: Agent | undefined;
  bonuses: SignUpReferralBonuses;
  licenses: string;
  activationBasedReferralParams: {
    isEnabled: boolean;
    activationReferralShareMessage: string;
  };
}

export function shareSignUpBasedReferralLink(props: ShareReferralContentProps) {
  const { bonuses } = props;
  return Share.share({
    dialogTitle: "Invite colleagues to join Clipboard Health",
    title: `Download the Clipboard Health app for a ${bonuses.maxReferralAmountPerUser} bonus!`,
    text: generateStaticShareMessage(props),
    url: getWelcomeDynamicUrl(),
  });
}
export function generateStaticShareMessage(props: ShareReferralContentProps) {
  const { bonuses, agent, licenses, activationBasedReferralParams } = props;

  const agentMsa = agent?.address?.metropolitanStatisticalArea ?? "";
  const referralCode = agent?.referralCode ?? "";
  const city = agentMsa?.split(",")[0];

  const { isEnabled, activationReferralShareMessage } = activationBasedReferralParams;

  if (isEnabled) {
    return activationReferralShareMessage;
  }

  return `Hey! Clipboard Health is the nursing marketplace I was telling you about - they are urgently looking for ${licenses} in ${city}. They will pay us both a $${
    bonuses.maxReferralAmountPerUser
  } bonus after you enroll. The offer expires tomorrow but it takes just 5 minutes and they pay instantly to any debit card or bank account. This is the code you need to enter: ${referralCode}. If you bring in others as well, you can actually make up to $${
    bonuses.maxReferralAmount
  }.

You can find the app here ${getWelcomeDynamicUrl()}`;
}
