import { IonContent } from "@ionic/react";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { InlineLoader } from "@src/lib/deprecatedCode";
import { Agent } from "@src/lib/interface/src";
import { addDays, endOfDay, isWithinInterval, startOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ReadyToEarn from "./ReadyToEarn";
import { ONBOARDING_STAGES } from "../../constants/ONBOARDING_STAGES";
import { updateAgentData } from "../api";
import { OnBoardingComponentProps } from "../model";
import { StyledIonPage } from "../style";
import "./ReadyToEarn.scss";

interface SignUpBasedReferralProps {
  pageIsVisible: boolean;
  onNext: () => void;
  agent?: Agent;
  expirationDays: number;
}

const Page = (props: SignUpBasedReferralProps) => {
  const { pageIsVisible, agent, onNext, expirationDays } = props;
  if (pageIsVisible) {
    return (
      <>
        <ReadyToEarn agent={agent} onNext={onNext} expirationDays={expirationDays} />
      </>
    );
  }

  return (
    <IonContent>
      <div className="page-loader" data-testid="loader">
        <InlineLoader loading={true} />
      </div>
    </IonContent>
  );
};

const handleStageUpdate = async (agent?: Agent): Promise<void> => {
  try {
    updateAgentData({
      stage: ONBOARDING_STAGES.READY_TO_EARN,
    });
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: `Unable to update agent data to set onboarding stage to ${
        ONBOARDING_STAGES.READY_TO_EARN
      } - ${error?.message}: ${JSON.stringify(error?.stack || error)}`,
      metadata: {
        workerId: agent?.userId,
        msa: agent?.address?.metropolitanStatisticalArea,
      },
    });
  }
};

export default function SignUpBasedReferrals({ agent, nextStagePath }: OnBoardingComponentProps) {
  const history = useHistory<{ skipUserTargetCheck: boolean | undefined }>();
  const [pageIsVisible, setPageIsVisible] = useState(false);

  const {
    [CbhFeatureFlag.SIGN_UP_REFERRAL_SCREEN_EXPIRATION_DAY]: signUpReferralScreenExpirationDays = 1,
  } = useCbhFlags();

  const onNext = (handleUpdate: boolean) => {
    if (handleUpdate) {
      handleStageUpdate(agent);
    }
    history.push(`${nextStagePath}`);
  };

  useEffect(() => {
    /**
     * FIXME - convert to derived props and use conditional to return `<Redirect>` component
     * This useEffect should not exist.
     */
    let isPromotionActive = false;
    if (agent?.createdAt) {
      const adjustedStartDate = startOfDay(new Date(agent.createdAt));
      const adjustedEndDate = endOfDay(
        addDays(new Date(agent.createdAt), signUpReferralScreenExpirationDays)
      );

      isPromotionActive = isWithinInterval(new Date(), {
        start: adjustedStartDate,
        end: adjustedEndDate,
      });
    }
    if (agent?.referralCode && isPromotionActive) {
      setPageIsVisible(true);
      return;
    }
    if (!agent?.referralCode) {
      deprecatedDoNotUseLogError({
        message: `Error while trying to access ready to earn page: no referral code available`,
      });
    }
    history.replace(nextStagePath ?? OnboardingRouterPath.SHOW_FRIENDS_SHIFTS);
  }, []);

  return (
    <StyledIonPage className="onboarding-page">
      <Page
        pageIsVisible={pageIsVisible}
        agent={agent}
        onNext={() => onNext(true)}
        expirationDays={signUpReferralScreenExpirationDays}
      />
    </StyledIonPage>
  );
}
